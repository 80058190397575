$color_blue_500: #236da9;

html {
  scrollbar-width: none;  
}

html::-webkit-scrollbar {
  display: none;
  /* width: 0px;
  background-color: transparent; */
}

body {
  max-width: 100%;
  margin: 0 auto;
  padding-top: 6rem;
}

@media only screen and (max-width: 375px) {
  body {
    padding-top: 5rem;
  }
}

h2 {
  font-size: calc(0.65em + 2vmin);
}

#page-mod-scorm-player #scormpage #scorm_object {
  height: 100% !important;
}

.formularios {
  padding: 1rem;
  .campo {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    input,
    select,
    .react-dropdown-select,
    textarea {
      padding: 0.5rem 1rem;
      /*             background-color: rgba($color: #fff, $alpha: 0.4); */
      border: 1px solid #797974;
      /* color: #000000; */
      margin-bottom: 8px;
      resize: none;
      &::placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
      }
    }
    input[type="checkbox"] {
      padding: 0.5rem 1rem;
      /*             background-color: rgba($color: #fff, $alpha: 0.4); */
      border: 1px solid #797974;
      /* color: #000000; */
      margin-bottom: 8px;
      resize: none;
      margin-right: 0.3em;
      &::placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff;
      }
    }
    label {
      color: #000;
    }
    .react-dropdown-select {
      color: #000;
      background-color: #fff;
      border-radius: 0.375rem;
    }
    .react-dropdown-select-input {
      padding: 0 !important;
      opacity: 0;
    }
  }
}

// Fondo Login, Registro, ForgotPassword
.dafi-fondo-auth {
  background-image: url("../img/fondo_auth.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

// Barra de navegacion
.menu-items ul li {
  margin-right: 8px;
}
.buscador {
  position: relative;
}
.buscador i {
  top: 8px;
  left: 5px;
  position: absolute;
}
.buscador input::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}
.buscador input {
  font-size: 1rem;
  padding-left: 1.7rem;
  outline: unset;
}
.buscador {
  input {
    &:focus {
      &:before {
        color: $color_blue_500 !important;
      }
      + i {
        color: #236da9;
      }
    }
  }
}

.content_menu_hamburguesa {
  position: relative;

  .menu_hamburguesa_items {
    cursor: pointer;
  }

  .menu_hamburguesa_items {
    position: absolute;
    top: 42px;
    left: -105px;
    z-index: 1000;
  }
}

.content_search {
  position: relative;

  .search {
    z-index: 1000;
    position: absolute;
    width: 100vw;
    top: 45px;
    right: -7.2rem;
    transform: translate(-0px, -0px);
    padding: 1rem;
    background-color: #236da9;

    input {
      color: #222;
      width: 250px;
    }

    input::placeholder {
      color: #222;
      text-align: center;
    }
  }
}

.opacity-img {
  img {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}

.active-opacity-img {
  img {
    opacity: 1;
  }
}

.menu-opciones-perfil {
  position: relative;

  .menu-opciones-perfil-items {
    position: absolute;
    top: 10.3vh;
    z-index: 1000;
    padding: 0.25rem;
    left: -5vw;
    width: 11.4vw;
    text-align: center;

    ul li {
      margin: 0 auto;
      color: #236da9;
      &:hover {
        background-color: #236da9;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .menu-opciones-perfil-items {
    left: -17vw !important;
    width: 30.4vw !important;
    top: 9.5vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .menu-opciones-perfil-items {
    top: 8.2vh !important;
  }
}
.hm-shadow {
  box-shadow: 1px 1px 10px #666;
}

@keyframes toRigth {
  from {
    transform: translate(450px, 0px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

.toRigth-hm {
  animation-name: toRigth;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 1285px) {
  h2 {
    word-break: break-word;
    line-height: normal;
    font-size: calc(1.25em + 0.1vmin);
  }
  /*p {  
        word-break: break-word;
        line-height: normal;
    }*/
}

@media only screen and (max-width: 1200px) {
  .userPhoto {
    width: 2.75em !important;
    height: 2.25em !important;
  }
}

@media only screen and (max-width: 1024px) {
  .userPhoto {
    width: 2.5em !important;
    height: 2.5em !important;
  }
}

//Jubicom styles
.bgMainJubicom {
  background: #236da9;

  h2,
  h4 {
    color: #ffffff;
  }
}

.bgMainDafi {
  background: #ffffff;
}

.jubicomHeaders {
  background: #f6b900;
  color: #236da9;
  font-weight: bold;
}

.dafiHeaders {
  background: #84b0c9;
  color: #ffffff;
  font-weight: bold;
}

.bgJubicom {
  background: #236da9;
}

.bgDafi {
  background: #84b0c9;
}

.cardSlider {
  width: 16vmax;
}

.customContainer {
  width: 70%;
}

.customContainerUrl {
  width: 100%;
  height: 50vh;
}

.DayPicker-Month {
  width: 75% !important;
}
.DayPicker-Footer {
  padding: 0 !important;
}
.DayPicker-Footer,
button {
  text-align: center;
}

/* Quiz */

.answersBoxes {
}

.answersBoxes .answerInfo {
  background-color: #d3e3ef;
  border-radius: 14px;
  //height: 5rem;
}
.answersBoxes .answerInfo .numQuestion {
  background-color: #236da9;
  border-radius: 14px 14px 0 0;
  padding: 0.5rem;
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  letter-spacing: -0.25px;
}

.answersBoxes .answerForm {
  color: #001a1e;
  background-color: #fff;
  border-radius: 0px 0px 14px 14px;
  padding: 1.5rem 2.5rem;
  height: 12.65rem;
}
.boxShadow {
  box-shadow: 0px 0px 10px 2px #236da936;
  border-radius: 14px;
}
.answersBoxes .answerForm p.tittleQuestion {
  color: #236da9;
  font-size: 18px;
  font-weight: 900;
}
.answersBoxes .answerForm p.selectAnswer {
  font-size: 16px;
  font-weight: 300;
}

.answersBoxes .answerForm label,
.answersBoxes .answerForm span,
.answersBoxes .answerForm input {
  font-size: 16px;
  font-weight: 300;
  margin-right: 0.5rem;
}

.btnFinishExam {
  width: max-content;
  background-color: #236da9;
  border-radius: 12px;
  padding: 0.55rem 1rem;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
}

/*  */
.addNewCard {
  display: flex;
  align-items: center;
}
.btnAddNewTopic {
  width: max-content;
  background: #236da9;
  color: #fff;
  padding: 0.15rem 0.75rem;
  font-size: 16px;
  font-weight: 800;
}

.btnForumSuccess {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #236da9;
}
.btnForumSuccess:focus {
  box-shadow: 0 0 0 3px #236da985;
}

.btnForumCancel {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
  color: #fff;
  font-size: 1em;
  border-radius: 0.25em;
  background: #6e7881;
}
.btnForumCancel:focus {
  box-shadow: 0 0 0 3px #236da985;
}
.cardDiscussion {
  box-shadow: 0px 0px 10px 2px #00000059;
  border-radius: 14px;
  padding: 1rem;
}

.autorCard {
  display: flex;
  align-items: center;
}
.autorCard .autorContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalLine {
  border-left: 1px solid #858585;
  height: 27.5px;
}


.resultSearchBar {
  z-index: 9;
  position: absolute;
  width: 100vw;
  top: 6.75rem;
  right: -5.35rem;
  transform: translate(0);
  padding: 1rem;
  text-align: center;
  background-color: #fff;
}

.DayPicker-Day {
  font-size: 1.35rem;
}

.swal2-styled.swal2-confirm {
  background-color: #2778c4 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px #2778c480 !important;
}

@media only screen and (max-width: 1024px) {
  .cardSlider {
    width: 25vmax;
  }
  .answersBoxes .answerForm {
    margin-left: 0;
  }
}

@media only screen and (max-width: 540px) {
  .customContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .cardSlider {
    width: 30vmax;
  }
  .toRigth-hm {
    animation-name: unset;
    animation-duration: unset;
    animation-fill-mode: unset;
  }
  .answersBoxes .answerForm {
    padding: 1.5rem;
  }
}

@media only screen and (max-width: 360px) {
  .cardSlider {
    width: 30vmax;
  }
  .toRigth-hm {
    animation-name: unset;
    animation-duration: unset;
    animation-fill-mode: unset;
  }
}


.btn-close-course{
  top: 0;
  position: absolute;
  height: 57px;
  width: 57px;
}

.swal2-container{
  z-index: 9999999 !important;
}