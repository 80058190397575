@keyframes backInRight {
  0% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.backInRight {
  animation-name: backInRight;
}

.v-hidden {
  visibility: hidden;
}

.visibility-v {
  visibility: visible;
}

.effect {
  position: relative;
  left: 45%;
  transform: translateX(-47%);
  transition: right 2s, transform 2s;
  background-color: #ffffff;
}

.effect-right {
  @extend .backInRight;
}

ul.react-multi-carousel-track {
  margin-top: 1.5rem;
}
.react-multiple-carousel__arrow {
  background: rgba(35, 109, 169, 0.5) !important;
  min-width: 29px;
  min-height: 23px;
  &:hover {
    background: rgba(35, 109, 169, 1) !important;
  }
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}



// .effect2 {
//   transition: left .3s ease-in-out;
// -webkit-transition: left .3s ease-in-out;
// transition: right .3s ease-in-out;
// -webkit-transition: right .3s ease-in-out;
// }

// .container {
//   .effect-right {
//     @extend .backInRight;
//   }
// }
