.calendar_width {
    width: 100%;
    display: flex;
    max-width: 1120px;
}
.calendar_width > div:nth-child(1) {
    width: 100%;
}

.calendar_width > div:nth-child(1) > div:nth-child(1) {
    width: 100%;
    height: 500px;
}

.calendar_width > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
    height: 100%;
}

.calendar_events_list::-webkit-scrollbar {
    width: 0;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .calendar_width {
        flex-direction: column;
    }

    .calendar_width > div:nth-child(1) > div:nth-child(1) {
        height: auto;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .calendar_width {
        flex-direction: column;
    }
    .calendar_width > div:nth-child(1) > div:nth-child(1) {
        height: auto;
    }
   
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .calendar_width {
        flex-direction: column;
    }

    .calendar_width > div:nth-child(1) > div:nth-child(1) {
        height: auto;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .calendar_width {
        flex-direction: row;
    }

    .calendar_width > div:nth-child(1) > div:nth-child(1) {
        height: auto;
    }
}

@media only screen and (min-width: 1200px) {
    .calendar_width > div:nth-child(1) > div:nth-child(1) {
        height: 500px;
    }

    .calendar_width_home > div:nth-child(1) > div:nth-child(1) {
        height: auto;
    }
}