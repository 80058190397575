.event {
    padding: 12px;
    width: 300px;
    max-width: 300px;
    background: #fbfbfb;
    margin-left: 0.3rem;
}

.event > p {
    margin: 1rem 0;
}

.event__title {
    display: flex;
    justify-content: space-between;
}

.event__title h5 {
    margin-right: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    overflow-wrap: break-word;
}

.event__date_date {
    display: flex;
    flex-direction: column;
}

.event__date_date span {
    font-size: smaller;
    display: block;
}

.event_title {
    color: black;
    font-weight: bold;
    margin-top: 1rem;
    display: block;
}

.event_btn a {
    display: block;
    background: #367CF7;
    width: 50%;
    border-radius: 50px;
    text-align: center;
    padding: 0.5rem;
    margin: auto;
    margin-top: 20%;
    color: white;
}


/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
    .event {
        max-width: 100%;
        width: auto;
        margin-top: 1rem;
    }
    
} */
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
    .event {
        max-width: 100%;
        width: auto;
        margin-top: 1rem;
    }
} */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
    .event {
        max-width: 100%;
        width: auto;
        margin-top: 1rem;
    }
} */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .event {
        max-width: 100%;
        width: auto;
        margin-top: 1rem;
    }
}